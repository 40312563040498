import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { MutationDeleteActivityArgs, Responsible } from "@/graphql/types";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { setActiveActivity } from "@/graphql/session";
import { activeActivity, ActiveActivity } from "@/plugins/i18n";
import { useSecurityCounter } from "@/graphql/activity/secutrity-counter";

type DeleteActivityData = {
  deleteActivity: Responsible;
};

const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($id: Int!) {
    deleteActivity(id: $id) {
      id
    }
  }
`;

export const useDeleteActivity = () => {
  const toast = useToast();
  const { t } = useI18n();
  const { counter, dialog, preventDelete } = useSecurityCounter();
  const { loading, mutate, onDone } = useMutation<
    DeleteActivityData,
    MutationDeleteActivityArgs
  >(DELETE_ACTIVITY);
  onDone(({ data }) => {
    const success = !!data?.deleteActivity;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("delete.title"),
      detail: t(`activity.delete.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    dialog.show = !success;
  });

  function confirmDelete(id: number) {
    void mutate(
      { id },
      {
        update(cache, { data }) {
          if (data?.deleteActivity) {
            const id1 = data.deleteActivity.id;
            if (activeActivity.value.id === id) {
              /**reset active activity when it's deleted*/
              setActiveActivity(new ActiveActivity());
            }
            cache.modify({
              fields: {
                userResponsible(existingRef: any[], { readField }) {
                  return existingRef.filter(
                    (eRef: any) => readField("id", eRef) !== id1
                  );
                },
                activityResponsible(existingRef: any, { readField }) {
                  return existingRef.filter(
                    (eRef: any) => readField("id", eRef) !== id1
                  );
                },
              },
            });
          }
        },
      }
    );
  }
  return { loading, preventDelete, confirmDelete, dialog, counter };
};

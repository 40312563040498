
import { defineComponent } from "vue";
import { useDeleteActivity } from "@/graphql/activity/delete.activity";

export default defineComponent({
  name: "DeleteActivity",
  props: {
    id: Number,
  },
  setup() {
    return {
      ...useDeleteActivity(),
    };
  },
});

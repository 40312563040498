import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Button, {
      onClick: _ctx.preventDelete,
      loading: _ctx.loading,
      class: "p-button-danger",
      "icon-pos": "right",
      icon: "pi pi-trash"
    }, null, 8, ["onClick", "loading"]), [
      [_directive_tooltip, _ctx.$t('remove')]
    ]),
    _createVNode(_component_Dialog, {
      header: _ctx.$t('activity.delete.read'),
      visible: _ctx.dialog.show,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialog.show) = $event)),
      breakpoints: { '960px': '80vw' },
      style: { width: '40vw' },
      modal: true,
      "content-class": "p-d-flex p-ai-center"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('cancel'),
          icon: "pi pi-times",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog.show = false)),
          class: "p-button-text",
          autofocus: ""
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          icon: "pi pi pi-exclamation-triangle",
          label: _ctx.counter !== 0 ? _ctx.counter : _ctx.$t('confirm'),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmDelete(_ctx.id))),
          class: "p-button-raised p-button-danger",
          disabled: !_ctx.dialog.checked || _ctx.counter !== 0,
          "icon-pos": "right"
        }, null, 8, ["label", "disabled"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Checkbox, {
          modelValue: _ctx.dialog.checked,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialog.checked) = $event)),
          binary: true
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t("activity.delete.checked")), 1)
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}